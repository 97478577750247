<script lang="ts" setup>
import { Link } from '~/types'

const props = defineProps<{
  items: Link[]
}>()

const MIN_ITEMS = 2
const MAX_CHARS = 40

const shouldShow = computed(() => props.items.length > MIN_ITEMS - 1)
const backPath = shouldShow && props.items.at(-2)

const items = computed(() => {
  return props.items.reduce((acc, item, index) => {
    if (index === 0) {
      return acc
    }

    if (item.text && item.text.length > MAX_CHARS) {
      return [...acc, { ...item, text: item.text.slice(0, MAX_CHARS) + '...', title: item.text }]
    }

    return [...acc, item]
  }, [] as Link[])
})
</script>
<template>
  <nav v-if="shouldShow" class="breadcrumbs">
    <ol class="items cul">
      <li v-for="(item, index) in items" :key="index" class="item">
        <NuxtLink class="link text-xsmall" :to="item.url" :title="item.title"> {{ item.text }} </NuxtLink>
        <Icon class="icon" name="link-arrow" />
      </li>
    </ol>
    <NuxtLink v-if="backPath" class="back text-xsmall" :to="backPath.url">
      <Icon name="link-arrow" />
      {{ backPath.text }}
    </NuxtLink>
  </nav>
</template>
<style scoped lang="scss">
.breadcrumbs {
  color: var(--c-text-light);
}
.items {
  display: none;

  @include breakpoint(tl) {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 18px;

  &:last-child {
    .icon {
      display: none;
    }
  }
}

.back {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    transform: rotate(180deg);
  }

  @include breakpoint(tl) {
    display: none;
  }
}
</style>
